import React from 'react';
import '../../dist/css/main.css';

import { updateExporter } from '../../actions/exporters';

import ExporterSidebar from '../../components/sidenav/ExporterSidenav';
import CompanyProfile from '../../components/CompanyProfile';
import CompanyRepProfile from '../../components/CompanyRepProfile';
import CompanyDocuments from '../../components/CompanyDocuments';
import DashBoardHeader from '../../components/DashBoardHeader';

const Dashboard = () => {
    return (
        <div className="grid-container">
            <DashBoardHeader />
            <ExporterSidebar />
            <main className="main">
                <div className="main-header">
                    <div className="main-header__heading">
                        <span className="main-header__menu">Dashboard | </span>
                        <span className="main-header__sub-menu">Profile</span>
                    </div>
                </div>
                <div className="parent-container">
                    <CompanyProfile />
                    <CompanyRepProfile />
                </div>
                <CompanyDocuments />
            </main>
            <div className="footer"></div>
        </div>
    )
}

export default Dashboard;
