export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';

export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const FETCH_ALL = 'FETCH_ALL';

export const CREATE_PERMIT = 'CREATE_PERMIT';
export const FETCH_EXPORTER_PERMITS = 'FETCH_EXPORTER_PERMITS';

export const FETCH_PERMITS_PENDING = 'FETCH_PERMITS_PENDING';
export const FETCH_PERMITS_SUCCESS = 'FETCH_PERMITS_SUCCESS';
export const FETCH_PERMITS_ERROR = 'FETCH_PERMITS_ERROR';