import React from 'react';

import ExporterSidenav from '../../components/sidenav/ExporterSidenav';
import DashBoardHeader from '../../components/DashBoardHeader';
import UpdateCompanyRepForm from '../../components/forms/UpdateCompanyRepForm';

const UpdateCompanyRepProfile = () => {
    return (
        <div className="grid-container">
            <DashBoardHeader />
            <ExporterSidenav />
            <main className="main">
            <div className="main-header">
                <div className="main-header__heading">
                    <span className="main-header__menu">Dashboard | </span>
                    <span className="main-header__menu">Profile | </span>
                    <span className="main-header__sub-menu">Update Representative's Profile</span>
                </div>
            </div>
            <div className="permit-form">
                <UpdateCompanyRepForm />
            </div>
            </main>
        </div>
    )
}

export default UpdateCompanyRepProfile;
