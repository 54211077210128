import React, {useState} from 'react';
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import * as actionType from '../constants/actionTypes';

const LoggedInNav = () => {
 const [exporter, setExporter] = useState(JSON.parse(localStorage.getItem('data')));
 console.log(exporter);

const dispatch = useDispatch();
const history = useHistory();

const logout = () => {
  dispatch({ type: actionType.LOGOUT });
  history.push('/');
  setExporter(null);
};

// const dashboardRoute = () => {
//   {if(Exporter)){
//     dashboardRoute = (
//     <Link to= "/exporterdashboard" className="page-scroll ancho">
//       Dashboard
//     </Link>
//     )
// } if(Admin){
//   dashboardRoute =(
//     <Link to="/cedadmin" className="page-scroll ancho">
//       Dashboard
//     </Link>
//   )
// }
// }

    return (
        <ul className="nav navbar-nav navbar-right">
              <li>
                <Link to="/" className="page-scroll ancho">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/permittracking" className="page-scroll ancho">
                  Permit Tracking
                </Link>
              </li>
              <li>
                <Link to="/photogallery" className="page-scroll ancho">
                  Photo Gallery
                </Link>
              </li>
              <li>
                <Link to="/login" className="btn btn-register" onClick={logout} >
                  Log Out
                </Link>
              </li>
              <li>
              <Link to= "/exporterdashboard" className="page-scroll ancho">
                  Dashboard
              </Link>
              </li>
            </ul>
    )
}

export default LoggedInNav
