import React, {useState} from 'react';
import '../../dist/css/main.css';

import RecommenderSidenav from '../../components/sidenav/RecommenderSidenav';
import Searchbar from '../../components/Searchbar';
import DashBoardHeader from '../../components/DashBoardHeader';
import { AllPermits } from '../../components/Permits/AllPermits';
import { RecommenderPermits } from '../../components/Permits/RecommenderPermits';

const RecommenderDashboard = () => {

    return (
        <div className="grid-container">
            <DashBoardHeader />
            <RecommenderSidenav />
            <main className="main">
                <div className="main-header">
                    <div className="main-header__heading">
                        <span className="main-header__menu">Dashboard | </span>
                        <span className="main-header__sub-menu">Home</span>
                    </div>
                </div>
                <Searchbar />
                <h3>Export Permit To Recommend</h3>
                <RecommenderPermits />
            </main>
            <div className="footer"></div>
        </div>
    )
}

export default RecommenderDashboard;
