import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";

import User from '../dist/images/user.jpg';

const CompanyRepProfile = () => {
    const exporter = useSelector((state) => state.auth.user);
    
    useEffect(() => {
    }, []);

    return (
        <>
        <div className="child2">
            <h3>Company Representative Profile</h3>
                <div className="user-profile">
                    <div className="profile-image">
                        <img src={User} className="icon-group" alt="user-image"/>
                    </div>
                    <div className="overviewcard__info">
                        <div className="title">Role</div>
                        <div className="user"><span>{exporter?.representative_role}</span></div>
                    </div>
                    <div className="overviewcard__info">
                        <div className="title">Status</div>
                        <div className="active">{exporter?.representative_status} </div>
                    </div>
                    <div className="overviewcard__info">
                        <div className="title">Asigned Since</div>
                        <div className="title-value">{exporter?.representative_createdAt}</div>
                    </div>
                </div>
                <div>
                <div className="inner-row">
                    <div className="inner-column">
                        <div className="title">Name</div>
                        <div className="title-value">{exporter?.representative_name}</div>
                    </div>
                    <div className="inner-column">
                        <div className="title">Email</div>
                        <div className="title-value">{exporter?.representative_email}</div>
                    </div>
                </div>
                <div className="inner-row">
                    <div className="inner-column">
                        <div className="title">Location</div>
                        <div className="title-value"><p>{exporter?.representative_address}</p></div>
                    </div>
                    <div className="inner-column">
                        <div className="title">Phone</div>
                        <div className="title-value">{exporter?.representative_phone}</div>
                    </div>
                </div>
                <div className="inner-row">
                    <div className="inner-column">
                        <div className="title">Gender</div>
                        <div className="title-value">{exporter?.representative_gender}</div>
                    </div>
                    <div className="inner-column">
                        <div className="title">Interest</div>
                        <div className="title-value">{exporter?.representative_interest}</div>
                    </div>
                </div>
                <div className="user-profile-btn">
                    <Link to='/updatecompanyrepprofile' class="click">Edit Representative Profile</Link>
                </div>
                </div>
            </div> 
        </>
    )
}

export default CompanyRepProfile
