import React, {useState} from 'react';
import '../../dist/css/main.css';


import OverviewCards from '../../components/OverviewCards';
import Searchbar from '../../components/Searchbar';
import CEDSidenav from '../../components/sidenav/CEDSidenav';
import CEDAdminTable from '../../components/tables/CEDAdminTable';
import DashBoardHeader from '../../components/DashBoardHeader';

const PendingApproval = () => {

    return (
        <div className="grid-container">
            <DashBoardHeader />
            <CEDSidenav />
            <main className="main">
                <div className="main-header">
                    <div className="main-header__heading">
                        <span className="main-header__menu">Dashboard | </span>
                        <span className="main-header__menu">Profile Mangement | </span>
                        <span className="main-header__sub-menu">View Profiles</span>
                    </div>
                </div>
                <Searchbar />
                <h3>Commodities & Export Department Admins</h3>
                <CEDAdminTable />
            </main>
            <div className="footer"></div>
        </div>
    )
}

export default PendingApproval;
