import { FETCH_ALL, UPDATE } from '../constants/actionTypes';

export default (exporters = [], action) => {
  switch (action.type) {
    case UPDATE:
      return exporters.map((exporter) => (exporter.id === action.payload.id ? action.payload : exporter));
    default:
      return exporters;
  }
};

