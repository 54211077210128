import React, { useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import {getAllPermits} from '../../actions/permits';
import { useDispatch } from 'react-redux';

export const VerifierPermits = () => {
    const verifierPermits = useSelector((state) => state.permits);
    const check = useSelector((state) => state.auth.user);
    console.log(check);
    const dispatch = useDispatch();
    
    useEffect(() => {
        const verifierPermits = getAllPermits();
        verifierPermits();
        dispatch(getAllPermits());
    }, []);

    return (
        <>
        {!verifierPermits.length ? <h6>You Have No Export Activity</h6> : 
            <table>
                <thead>
                    <tr>
                        <th className="table-head" data-column="S/N">S/N</th>
                        <th className="table-head" data-column="product">Product</th>
                        <th className="table-head" data-column="exporter">Quantity</th>
                        <th className="table-head" data-column="permitNumber">Application Date</th>
                        <th className="table-head" data-column="status">Consignee Name</th>
                        <th className="table-head" data-column="consignee">Consignee Location</th>
                        <th className="table-head" data-column="action">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {verifierPermits.map(( verifier, index, ) => {
                        return (
                            <tr key={verifier._id}>
                            <td>{index + 1}</td>
                            <td>{verifier.productDescription}</td>
                            <td>{verifier.quantity}</td>
                            <td>{verifier.updatedAt}</td>
                            <td>{verifier.consigneeName}</td>
                            <td>{verifier.consigneeCity}</td>
                            <td><Link to={'/' + verifier._id} className="click">View</Link></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        }
        </>
    )
}