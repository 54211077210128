import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import { PersistGate } from "redux-persist/integration/react";

import { reducers } from "./reducers";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import "./index.css";
import App from "./App";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [ "auth" ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const getStoreAndPersistor = () => {
  let store = createStore(persistedReducer, compose(applyMiddleware(thunk)));
  let persistor = persistStore(store);
  return { store, persistor };
};

export const { store, persistor } = getStoreAndPersistor();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
