import React, {useState} from 'react';
import '../../dist/css/main.css'


function NavItem(props) {
    const [open, setOpen] = useState(false);

    return (
        <li  className="sidenav__list-item" onMouseEnter={() => setOpen(!open)} onMouseLeave={() => setOpen(false)}>
                <span className="sidenav-icon">{props.icon}</span>
                <span>{props.title}</span>
                <span>{props.arrowDown}</span>
            {open && props.children}
        </li>
         
    );
}

export default NavItem;
