import React from 'react'

const DashBoardHeader = () => {
    return (
        <div className="header">
                <h4>Federal Ministry of Industry, Trade and Investments</h4>
                <p>Commodities and Export Department</p>
        </div>
    )
}

export default DashBoardHeader;
