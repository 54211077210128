import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { verifiersignin } from '../actions/auth';
import Navigation from '../components/navigation';

const AdminLogin = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [adminSignInData, setAdminSignInData] = useState({ idNumber: '', password: '' });

  //const { email, password } = adminSignInData;

  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(adminSignInData);
    dispatch(verifiersignin( adminSignInData, history ));
  };

  const handleChange = (e) => {
    setAdminSignInData({ ...adminSignInData, [e.target.name] : e.target.value });
  };

//  const handleShowPassword = () => setShowPassword((prevShowPassword) => !prevShowPassword);
  
    return (
      <>
      <Navigation />
        <div id="contact">
          <div className="container">
            <div className="col-md-8">
              <div className="row">
                <form name="AdminlogInForm" id="logInForm" onSubmit={handleSubmit} noValidate>
                <div className="section-title">
                  <h2>EXPORT PERMIT CHECKER LOGIN</h2>
                </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          name="idNumber"
                          className="form-control"
                          placeholder="Checker ID"
                          required="required"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          name="password"
                          label="Password"
                          type={showPassword ? "text" : "password"}
                          id="password"
                          className="form-control"
                          placeholder="Password"
                          required="required"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-custom btn-lg">
                    Login
                  </button>
                </form>
              </div>
            </div>
            <div className="col-md-3 col-md-offset-1 contact-info">
              <div className="contact-item">
                <h3>Contact Info</h3>
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Address
                  </span>
                  Abuja, Nigeria
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Phone
                  </span>{" "}
                  080123456789
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  contact@trademetrology@.com
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="social">
                <ul>
                    <li>
                        <Link to="/login">EXPORTER</Link>
                    </li>
                    <li>
                    <Link to="/adminlogin">CED ADMIN</Link>
                    </li>
                    <li>
                    <Link to="/recommenderlogin">RECOMMENDER</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
}

export default AdminLogin;
