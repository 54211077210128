import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

import { updateExporter } from "../../actions/exporters";

const UpdateCompanyForm = () => {
  const exporter = useSelector((state) => state.auth.user);

  const [updateData, setupdateData] = useState({ ...exporter });

  const dispatch = useDispatch();
  const history = useHistory();

  const handleFormSubmit = (e) => {
    e.preventDefault();
    dispatch(updateExporter(updateData._id, updateData, history));
    console.log(updateData);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setupdateData({ ...updateData, [e.target.name]: e.target.value });
  };

  return (
    <form
      name="updateCompanyForm"
      id="updateCompanyForm"
      onSubmit={handleFormSubmit}
      noValidate
    >
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="companyName">Company Name</label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              className="form-control"
              value={updateData.companyName}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              className="form-control"
              value={updateData.email}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="rcNumber">RC Number</label>
            <input
              type="rcNumber"
              id="rcNumber"
              name="rcNumber"
              className="form-control"
              value={updateData.rcNumber}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="address">Address</label>
            <input
              type="text"
              id="address"
              name="address"
              className="form-control"
              value={updateData.address}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <input
              type="text"
              id="phone"
              name="phoneNumber"
              className="form-control"
              value={updateData.phoneNumber}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="postal">Pos tal Code</label>
            <input
              type="text"
              id="postal"
              name="postalCode"
              className="form-control"
              value={updateData.postalCode}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="city">City</label>
            <input
              type="text"
              id="city"
              name="city"
              className="form-control"
              value={updateData.city}
              onChange={handleChange}
            />
          </div>
        </div>
        <div id="success"></div>
        <button type="submit" className="click" value="submit">
          Update Company's Profile
        </button>
      </div>
    </form>
  );
};

export default UpdateCompanyForm;
