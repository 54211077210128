import React from 'react';
import { Link } from 'react-router-dom';

import '../../dist/css/main.css'

import NavItem from './NavItem';
import { ProfileDropdown, ExportDropdown } from './DropdownMenu';


import Logo from '../../dist/images/coatofarm.png';
import HomeIcon from '../../dist/images/home.svg';

import * as FaIcons from 'react-icons/fa'

function CEDSidenav(props) {
    
    return (
        <nav className="sidenav">
            <img src={Logo} className="dashboard-logo" alt="logo"/>
            <ul className="sidenav__list">
                <div className="company-identity">
                    <h4>ADMIN</h4>
                    <p>Commodities & Export Department</p>
                </div>
                <Link to="/cedadmindashboard">
                    <li className="sidenav__list-item active-tab">
                        <img src={HomeIcon} className="sidenav-icon" alt="homeicon"/> 
                        <span>Home</span>
                    </li>
                </Link>
                <NavItem
                    icon={<img src={HomeIcon} className="sidenav-icon" alt="homeicon"/>}
                    title="Profiles Management"
                >
                    <ProfileDropdown />
                </NavItem>
                <NavItem
                    icon={<img src={HomeIcon} className="sidenav-icon" alt="homeicon"/>}
                    title="Export Management"
                >
                    <ExportDropdown />
                </NavItem>
                    
                <div className="nav-user-profile">
                <li className="sidenav__logout-item">
                    <img src={HomeIcon} className="sidenav-icon" alt="homeicon"/> 
                    <span><Link to="/signUp">Logout</Link></span>
                </li> 
                </div>
            </ul>
        </nav>
    )
}

export default CEDSidenav;

