import React, { Component } from "react";

export class Footer extends Component {
  render() {
    return (
      <div>
        <div id="footer">
          <div className="container text-center">
            <p>
              &copy; 2021 Trade Metrology.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
