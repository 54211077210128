import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";

//import Logo from '../dist/images/LocalisNotFaker.jpg';

const CompanyProfile = () => {
    const exporter = useSelector((state) => state.auth.user);
    
    useEffect(() => {
    }, []);

    return (
        <>
        <div className="child1">
            <h3>Official Company Profile</h3>
                <div className="user-profile">
                    <div className="profile-image">
                        <img src={exporter?.userPic} className="icon-group" alt="Logo"/>
                    </div>
                    <div className="overviewcard__info">
                        <div className="title">Account</div>
                        <div className="user"><span>{exporter?.account}</span></div>
                    </div>
                    <div className="overviewcard__info">
                        <div className="title">RC No.</div>
                        <div className="active">{exporter?.rcNumber}</div>
                    </div>
                    <div className="overviewcard__info">
                        <div className="title">Signup Date</div>
                        <div className="title-value">{exporter?.createdAt}</div>
                    </div>
                </div>
                <div>
                <div className="inner-row">
                    <div className="inner-column">
                        <div className="title">Name of Export Company</div>
                        <div className="title-value">{exporter?.companyName}</div>
                    </div>
                    <div className="inner-column">
                        <div className="title">Email</div>
                        <div className="title-value">{exporter?.email}</div>
                    </div>
                </div>
                <div className="inner-row">
                    <div className="inner-column">
                        <div className="title">Address</div>
                        <div className="title-value"><p>{exporter?.address}</p></div>
                    </div>
                    <div className="inner-column">
                        <div className="title">Phone</div>
                        <div className="title-value">{exporter?.phoneNumber}</div>
                    </div>
                </div>
                <div className="inner-row">
                    <div className="inner-column">
                        <div className="title">Postal Code</div>
                        <div className="title-value">{exporter?.postalCode}</div>
                    </div>
                    <div className="inner-column">
                        <div className="title">City</div>
                        <div className="title-value">{exporter?.city}</div>
                    </div>
                </div>
                <div className="user-profile-btn">
                    <Link to='#' className="reset">Change Password</Link>
                    <Link to='/updatecompanyprofile' className="click">Edit Company Profile</Link>
                </div>
                </div>
            </div> 
        </>
    )
}

export default CompanyProfile
