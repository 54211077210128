import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import Logo from "../dist/images/coatofarm.png";
import LoggedInNav from "./LoggedInNav";
import LoggedOutNav from "./LoggedOutNav";

function Navigation() {
  const exporter = useSelector((state) => state.auth.user);
  // const [exporter, setExporter] = useState(JSON.parse(localStorage.getItem('data')));
  // const dispatch = useDispatch();
  // const history = useHistory();

  
  // const logout = () => {
  //   dispatch({ type: actionType.LOGOUT });
  //   history.push('/');
  //   setExporter(null);
  // };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <div className="navbar-brand page-scroll ancho">
            <img
              src={Logo}
              alt="Trade Metrology logo"
              className="site-logo"
              component={Link}
              to="/"
            />
          </div>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          {exporter ? <LoggedInNav  /> : <LoggedOutNav />}
        </div>
      </div>
    </nav>
  );
}

export default Navigation;
