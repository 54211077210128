import React from 'react';

 const PendingPermitsData = [
    {
        id: 1,
        exporter: "Dan Baba Group",
        product: 'Crude Oil',
        permitNo: 'BC1243PE',
        applicationDate: '11/02/2021',
        status: 'pending',
        consignee: 'Alhaji Musa Tanko',
        destination: 'Moroco'
    },
    {
        id: 2,
        exporter: "Dan Baba Group",
        product: 'Gas',
        permitNo: 'GI5541JJ',
        applicationDate: '02/02/2021',
        status: 'pending',
        consignee: 'Shuan Shi',
        destination: 'China'
    },
    {
        id: 3,
        exporter: "Dan Baba Group",
        product: 'Crude Oil',
        permitNo: 'AJ0976DE',
        applicationDate: '08/01/2021',
        status: 'pending',
        consignee: 'Mr. James Vendi',
        destination: 'Kenya'
    },
    {
        id: 4,
        exporter: "Dan Baba Group",
        product: 'Diesel',
        permitNo: 'GK4366SE',
        applicationDate: '13/01/2021',
        status: 'pending',
        consignee: 'Mr. James Vendi',
        destination: 'Autralia'
    },
    {
        id: 5,
        exporter: "Dan Baba Group",
        product: 'Crude Oil',
        permitNo: 'AK4700NA',
        applicationDate: '27/12/2020',
        status: 'pending',
        consignee: 'Dr. Noah Wayne',
        destination: 'Germany'
    },
    {
        id: 6,
        exporter: "Dan Baba Group",
        product: 'Crude Oil',
        permitNo: 'BC1243PE',
        applicationDate: '11/02/2021',
        status: 'pending',
        consignee: 'Alhaji Musa Tanko',
        destination: 'Moroco'
    },
    {
        id: 7,
        exporter: "Dan Baba Group",
        product: 'Gas',
        permitNo: 'GI5541JJ',
        applicationDate: '02/02/2021',
        status: 'pending',
        consignee: 'Shuan Shi',
        destination: 'China'
    },
    {
        id: 8,
        exporter: "Dan Baba Group",
        product: 'Crude Oil',
        permitNo: 'AJ0976DE',
        applicationDate: '08/01/2021',
        status: 'pending',
        consignee: 'Mr. James Vendi',
        destination: 'Kenya'
    },
    {
        id: 9,
        exporter: "Dan Baba Group",
        product: 'Diesel',
        permitNo: 'GK4366SE',
        applicationDate: '13/01/2021',
        status: 'pending',
        consignee: 'Mr. James Vendi',
        destination: 'Autralia'
    },
    {
        id: 10,
        exporter: "Dan Baba Group",
        product: 'Crude Oil',
        permitNo: 'AK4700NA',
        applicationDate: '27/12/2020',
        status: 'pending',
        consignee: 'Dr. Noah Wayne',
        destination: 'Germany'
    },
];

export default PendingPermitsData;