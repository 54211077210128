import React from 'react';
import { Link } from 'react-router-dom';

import Pending from '../../dist/icons/pending.svg';
import Delivery from '../../dist/icons/delivery.svg';
import Done from '../../dist/icons/done.svg';

function Cards() {
    return (
        <div className="main-overview">
        <div className="overviewcard">
            <div className="overviewcard__icon blue-bg">
                <img src={Pending} className="icon-group" alt="groupicon"/> 
            </div>
            <div className="overviewcard__info">
                <div className="title">No. of Export Companies</div>
                <div className="figure">
                    {/* permits.length(); */}
                    <span><Link to="#" className="click">view</Link></span>
                </div>
            </div>
        </div>
        <div className="overviewcard">
            <div className="overviewcard__icon green-bg">
                <img src={Delivery} className="icon-group" alt="groupicon"/>
            </div>
            <div className="overviewcard__info">
                <div className="title">Permits(s) Pending Recommendation</div>
                <div className="figure"> 0
                    <span>
                        <Link to="/pendingrecommendation" className="click">view</Link>
                    </span>
                </div>
            </div>
        </div>
        <div className="overviewcard">
            <div className="overviewcard__icon orange-bg">
                <img src={Done} className="icon-group" alt="groupicon"/>
            </div>
            <div className="overviewcard__info">
                <div className="title">Permits(s) Pending Endorsement</div>
                <div className="figure">0
                    <span>
                        <Link to="/pendingendorsement" className="click">view</Link>
                    </span>
                </div>
            </div>
        </div>
        <div className="overviewcard">
            <div className="overviewcard__icon orange-bg">
                <img src={Done} className="icon-group" alt="groupicon"/>
            </div>
            <div className="overviewcard__info">
                <div className="title">Permits(s) Pending Approval</div>
                <div className="figure">0
                    <span>
                        <Link to="/pendingApproval" className="click">view</Link>
                    </span>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Cards
