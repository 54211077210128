import React, {useState} from 'react';
import '../../dist/css/main.css';


import OverviewCards from '../../components/OverviewCards';
import Searchbar from '../../components/Searchbar';
import CEDSidenav from '../../components/sidenav/CEDSidenav';
import DashBoardHeader from '../../components/DashBoardHeader';
import CreateCEDForm from '../../components/forms/CreateCEDForm';

const PendingApproval = () => {

    return (
        <div className="grid-container">
            <DashBoardHeader />
            <CEDSidenav />
            <main className="main">
                <div className="main-header">
                    <div className="main-header__heading">
                        <span className="main-header__menu">Dashboard | </span>
                        <span className="main-header__menu">Profile Mangement | </span>
                        <span className="main-header__sub-menu">Create Profile</span>
                    </div>
                </div>
                <Searchbar />
                <div className="permit-form">
                    <h3>Create New CED Admin</h3>
                    <CreateCEDForm />
                </div>
            </main>
            <div className="footer"></div>
        </div>
    )
}

export default PendingApproval;
