import React, {useState} from 'react';
import '../../dist/css/main.css';


import OverviewCards from '../../components/OverviewCards';
import Searchbar from '../../components/Searchbar';
import CEDSidenav from '../../components/sidenav/CEDSidenav';
import { PendingPermitsTable } from '../../components/Permits/PendingPermitsTable';
import DashBoardHeader from '../../components/DashBoardHeader';

const PendingApproval = () => {

    return (
        <div className="grid-container">
            <DashBoardHeader />
            <CEDSidenav />
            <main className="main">
                <div className="main-header">
                    <div className="main-header__heading">
                        <span className="main-header__menu">Dashboard | </span>
                        <span className="main-header__menu">Export Permits | </span>
                        <span className="main-header__sub-menu">Pending Approval</span>
                    </div>
                </div>
                <h3>Export Permit Pending Approval</h3>
                <Searchbar />
                <PendingPermitsTable />
            </main>
            <div className="footer"></div>
        </div>
    )
}

export default PendingApproval;
