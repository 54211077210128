import React, {useState} from 'react';
import Modal from 'react-modal';

import PendingPermitsData from '../../data/PendingPermitsData';
import ManagePermitModal from '../modals/ManagePermitModal';

export const PendingPermitsTable = () => {
    const [modalOpen, setMOdalOpen] = useState(false);
    const permits = (PendingPermitsData);
    return (
        <>
            <table>
                <thead>
                    <tr>
                        <th className="table-head" data-column="S/N">S/N</th>
                        <th className="table-head" data-column="exporter">Exporter</th>
                        <th className="table-head" data-column="product">Product</th>
                        <th className="table-head" data-column="permitNumber">Permit Number</th>
                        <th className="table-head" data-column="status">Status</th>
                        <th className="table-head" data-column="consignee">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {permits.map(( permit, index ) => {
                        return (
                            <tr>
                            <td>{permit.id}</td>
                            <td>{permit.exporter}</td>
                            <td>{permit.product}</td>
                            <td>{permit.permitNo}</td>
                            <td><span className={permit.status}>{permit.status}</span></td>
                            <td><button  
                                    className="click" 
                                    onClick={() => setMOdalOpen(true)} 
                                    > Manage
                                </button>
                            </td>
                                <Modal isOpen={modalOpen} onRequestClose={() => setMOdalOpen(false)}>
                                    <ManagePermitModal />
                                    <button className="click modal-close" onClick={() => setMOdalOpen(false)} >Close</button>
                                </Modal>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            
        </>
    )
}
