import axios from "axios";
import { store } from "../index";

const API = axios.create({ baseURL: "https://api.epermit.ng" });

API.interceptors.request.use((req) => {
  const token = store.getState().auth?.token;
  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }
  return req;
});

export const signUp = (signUpData) => API.post("/register", signUpData);
export const signIn = (signInData) => API.post("/login", signInData);
export const adminsignin = (adminSignInData) =>
  API.post("/adminlogin", adminSignInData);

export const updateExporter = (id, updatedExporter) =>
  API.put(`/user/${id}`, updatedExporter);

export const createPermit = (permitData) => API.post("/permit", permitData);
//export const fetchPermit = (id) => API.get(`/user/${id}/permit`);
export const fetchPermit = (id) => API.get(`user/${id}/permit`);
export const fetchAllPermits = () => API.get("/permit");

export const createCEDAdmin = (adminRegData) =>
  API.post("/adminregister", adminRegData);
