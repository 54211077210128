import React, {useState} from 'react';
import Modal from 'react-modal';

import ExporterSidenav from '../../components/sidenav/ExporterSidenav';
import { PermitApplicationForm } from '../../components/forms/PermitApplicationForm';
import DashBoardHeader from '../../components/DashBoardHeader';
import GuidelineModal from '../../components/modals/GuidelineModal';

const PendinPermits = () => {
    const [modalOpen, setMOdalOpen] = useState(false);
    return (
        <div className="grid-container">
            <DashBoardHeader />
            <ExporterSidenav />
            <main className="main">
            <div className="main-header">
                <div className="main-header__heading">
                    <span className="main-header__menu">Dashboard | </span>
                    <span className="main-header__menu">Export Permit | </span>
                    <span className="main-header__sub-menu">Permit Application</span>
                </div>
            </div>
            <button 
                className="click" 
                onClick={() => setMOdalOpen(true)} 
                > READ THE EXPORT PERMIT APPLICATION GUIDELINES
            </button>
            <Modal isOpen={modalOpen} onRequestClose={() => setMOdalOpen(false)}>
                <GuidelineModal />
                <button className="click" onClick={() => setMOdalOpen(false)} >Close</button>
            </Modal>
        <div className="permit-form">
        <h3>E-Permit Application Form</h3>
            <PermitApplicationForm />
        </div>
            </main>
        </div>
    )
}

export default PendinPermits;
