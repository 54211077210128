import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

import { updateExporter } from '../../actions/exporters';

const UpdateCompanyRepForm = () => {
    const exporter = useSelector((state) => state.auth.user);
  
    const [updateData, setupdateData] = useState({ ...exporter });
  
    const dispatch = useDispatch();
    const history = useHistory();
  
    const handleFormSubmit = (e) => {
      e.preventDefault();
      dispatch(updateExporter(updateData._id, updateData, history));
      console.log(updateData);
    };
  
    const handleChange = (e) => {
      e.preventDefault();
      setupdateData({ ...updateData, [e.target.name]: e.target.value });
    };

    return (
        <form 
            name="updateCompanyRepForm" 
            id="updateCompanyRepForm" 
            onSubmit={handleFormSubmit}
            noValidate
        >
                <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="name">Name of Repressentative</label>
                        <input
                            type="text"
                            id="name"
                            name="representative.name"
                            className="form-control"
                            value={updateData.repsName}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="representative.email"
                            className="form-control"
                            value={updateData.repsEmail}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="address">Address</label>
                        <input
                            type="text"
                            id="address"
                            name="representative.address"
                            className="form-control"
                            value={updateData.repsAddress}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="phoneNumber">Phone</label>
                    <input
                        type="text"
                        id="phoneNumber"
                        name="representative.phoneNumber"
                        className="form-control"
                        value={updateData.repsPhoneNumber}
                        onChange={handleChange}
                    />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="gender">Gender</label>
                        <select
                        type="select"
                        id="gender"
                        name="representative.gender"
                        className="form-control"
                        value={updateData.repsGender}
                        onChange={handleChange}
                        >
                        <option selected>Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        </select>
                      </div>
                    </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="interest">Interest</label>
                        <input
                            type="text"
                            id="interest"
                            name="representative.interest"
                            className="form-control"
                            value={updateData.repsInterest}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                {/* <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="status">Status</label>
                        <input
                            type="text"
                            id="status"
                            name="representative.status"
                            className="form-control"
                            value={updateData.status}
                            onChange={handleChange}
                        />
                    </div>
                </div> */}
                {/* <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="role">Role</label>
                        <input
                            type="text"
                            id="role"
                            name="representative.role"
                            className="form-control"
                            value={updateData.role}
                            onChange={handleChange}
                        />
                    </div>
                </div> */}
                <button type="submit" className="click">
                Update Representative's Profile
                </button>
            </div>
        </form>
    )
}

export default UpdateCompanyRepForm;