import React from 'react';

export const ExportersData = [
    {
        id: 1,
        company: 'Katashi Associates',
        reg: 'BC1243PE',
        email: 'hello@katashi.com',
        reps: 'Alhaji Musa Tanko',
        location: 'Kaduna'
    },
    {
        id: 2,
        company: 'Katashi Associates',
        reg: 'BC1243PE',
        email: 'hello@katashi.com',
        reps: 'Alhaji Musa Tanko',
        location: 'Kaduna'
    },
    {
        id: 3,
        company: 'Katashi Associates',
        reg: 'BC1243PE',
        email: 'hello@katashi.com',
        reps: 'Alhaji Musa Tanko',
        location: 'Kaduna'
    },
    {
        id: 4,
        company: 'Katashi Associates',
        reg: 'BC1243PE',
        email: 'hello@katashi.com',
        reps: 'Alhaji Musa Tanko',
        location: 'Kaduna'
    },
    {
        id: 5,
        company: 'Katashi Associates',
        reg: 'BC1243PE',
        email: 'hello@katashi.com',
        reps: 'Alhaji Musa Tanko',
        location: 'Kaduna'
    },
    {
        id: 6,
        company: 'Katashi Associates',
        reg: 'BC1243PE',
        email: 'hello@katashi.com',
        reps: 'Alhaji Musa Tanko',
        location: 'Kaduna'
    },
    {
        id: 7,
        company: 'Katashi Associates',
        reg: 'BC1243PE',
        email: 'hello@katashi.com',
        reps: 'Alhaji Musa Tanko',
        location: 'Kaduna'
    },
    {
        id: 8,
        company: 'Katashi Associates',
        reg: 'BC1243PE',
        email: 'hello@katashi.com',
        reps: 'Alhaji Musa Tanko',
        location: 'Kaduna'
    },
    {
        id: 9,
        company: 'Katashi Associates',
        reg: 'BC1243PE',
        email: 'hello@katashi.com',
        reps: 'Alhaji Musa Tanko',
        location: 'Kaduna'
    },
    {
        id: 10,
        company: 'Katashi Associates',
        reg: 'BC1243PE',
        email: 'hello@katashi.com',
        reps: 'Alhaji Musa Tanko',
        location: 'Kaduna'
    },
];
