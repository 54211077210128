import React from 'react';
import { Link } from 'react-router-dom';

import ExporterSidebar from '../../components/sidenav/ExporterSidenav';
import Searchbar from '../../components/Searchbar';
import { ExportTable } from '../../components/ExportTable';
import DashBoardHeader from '../../components/DashBoardHeader';

const PendinPermits = () => {
    return (
        <div className="grid-container">
            <DashBoardHeader />
            <ExporterSidebar />
            <main className="main">
            <div className="main-header">
                <div className="main-header__heading">
                    <span className="main-header__menu">Dashboard | </span>
                    <span className="main-header__menu">Export Permit | </span>
                    <span className="main-header__sub-menu">Pending Permit(s)</span>
                </div>
            </div>
            <Searchbar />
            <h3>Pending Export Permits</h3>
            <ExportTable />
            </main>
        </div>
    )
}

export default PendinPermits;
