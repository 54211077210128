import React from 'react';
import { Link } from 'react-router-dom';
import '../../dist/css/main.css';

function DropdownItem(props) {
      return (
        
        <Link to={props.link}>
          <li className="sidenav__list-item ">
            <span className="icon-button">{props.leftIcon}</span>
            {props.children}
            <span>{props.title}</span>
          </li>
        </Link>
      );
    }


const ProfileDropdown = () => {
  
    return (
      <div className="dropdown">
            <DropdownItem
            link="viewcedprofile"
            title="View Profiles"
            />
            <DropdownItem
            link="createcedprofiles"
            title="Create Profile"
            />
      </div>
    );
  };

const ExportDropdown = () => {
  
    return (
      <div className="dropdown">
            <DropdownItem
            link="cedexportcompanies"
            title="Export Companies"
            />
            <DropdownItem
            link="cedpermitapplications"
            title="Permit Applications"
            />
            <DropdownItem
            link="cedreturns"
            title="Export Returns"
            />
            <DropdownItem
            link="cedreports"
            title="Export Reports"
            />
            <DropdownItem
            link="cedenquiries"
            title="Export Enquiries"
            />
      </div>
    );
  };

const VerifierExportDropdown = () => {
  
    return (
      <div className="dropdown">
            <DropdownItem
            link="verifierexportcompanies"
            title="Export Companies"
            />
            <DropdownItem
            link="verifierreturns"
            title="Export Returns"
            />
            <DropdownItem
            link="verifierreports"
            title="Export Reports"
            />
            <DropdownItem
            link="verifierenquiries"
            title="Export Enquiries"
            />
      </div>
    );
  };

const RecommenderExportDropdown = () => {
  
    return (
      <div className="dropdown">
            <DropdownItem
            link="recommenderexportcompanies"
            title="Export Companies"
            />
            <DropdownItem
            link="recommenderreturns"
            title="Export Returns"
            />
            <DropdownItem
            link="recommenderreports"
            title="Export Reports"
            />
            <DropdownItem
            link="recommenderenquiries"
            title="Export Enquiries"
            />
      </div>
    );
  };


export {
  ProfileDropdown,
  ExportDropdown,
  VerifierExportDropdown,
  RecommenderExportDropdown,

} 
