  
import { UPDATE } from "../constants/actionTypes";
import * as api from "../api/index.jsx";

export const updateExporter = (id, exporter, history) => async (dispatch, res) => {
  try {
    const { data } = await api.updateExporter(id, exporter);
    const token = res?.tokenId;
    dispatch({ type: UPDATE, payload: { data, token } });
    history.push("/exporterprofile");
  } catch (error) {
    console.log(error);
  }
};