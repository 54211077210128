import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createCEDAdmin } from '../../actions/auth';

const initialAdminState = {
    name: '',
    idNumber: '',
    phoneNumber: '',
    location: '',
    password: '',
    role: ''
}

const CreateCEDForm = () => {
    const [adminRegData, setAdminRegData] = useState(initialAdminState);
  
    const dispatch = useDispatch();
    const history = useHistory();
    
    const handleFormSubmit = (e) => {
      e.preventDefault();
      dispatch(createCEDAdmin( adminRegData, history ));
      console.log(adminRegData);
    };
  
    const handleChange = (e) => {
      e.preventDefault();
      setAdminRegData({ ...adminRegData, [e.target.name] : e.target.value });
    };

    return (
        <form name="signUpForm" id="signUpForm" onSubmit={handleFormSubmit} noValidate>
                <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                    <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name of Admin"
                        onChange={handleChange}
                    />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                    <input
                        type="text"
                        id="idNumber"
                        name="idNumber"
                        className="form-control"
                        placeholder="ID Number"
                        onChange={handleChange}
                    />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                    <input
                        type="text"
                        id="phoneNumber"
                        name="phoneNumber"
                        className="form-control"
                        placeholder="Phone Number"
                        onChange={handleChange}
                    />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                    <input
                        type="text"
                        id="location"
                        name="location"
                        className="form-control"
                        placeholder="Location"
                        onChange={handleChange}
                    />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                    <input
                        type="password"
                        id="password"
                        name="password"
                        className="form-control"
                        placeholder="Passwprd"
                        onChange={handleChange}
                    />
                    </div>
                </div>
                <div className="col-md-12">
                      <div className="form-group">
                        <select
                          type="select"
                          id="role"
                          name="role"
                          className="form-control"
                          placeholder="Role"
                          onChange={handleChange}  
                        >
                        <option selected>units</option>
                        <option value="checker">Checker</option>
                        <option value="recommender">Recommender</option>
                        </select>
                      </div>
                    </div>
                <button type="submit" className="btn btn-custom btn-lg">
                Create
                </button>
            </div>
        </form>
    )
}

export default CreateCEDForm;