import React, { Component } from "react";
import axios from "axios";

import DashBoardHeader from "../../components/DashBoardHeader";
import VerifierSidenav from "../../components/sidenav/VerifierSidenav";
import PermitCompanyDetails from "../../components/Permits/PermitCompanyDetails";

import "../../dist/css/main.css";

class VerifierManagePermit extends Component {
  state = {
    verifier: null,
  };

  componentDidMount() {
    let id = this.props.match.params.verifier_id;
    axios.get("https://api.epermit.ng/permit/" + id).then((res) => {
      this.setState({
        verifier: res.data.data,
      });
      console.log(res.data.data);
    });
  }
  render() {
    return (
      <div className="grid-container">
        <DashBoardHeader />
        <VerifierSidenav />
        <main className="main">
          <div className="main-header">
            <div className="main-header__heading">
              <span className="main-header__menu">Dashboard | </span>
              <span className="main-header__menu">Export Permits | </span>
              <span className="main-header__sub-menu">Manage Permit</span>
            </div>
          </div>
          <PermitCompanyDetails />
          <div className="permit-header">
            <div>
              <span className="main-header__sub-menu">Product Details</span>
            </div>
            {this.state.verifier ? (
              <table>
                <thead>
                  <tr>
                    <th className="title">Product</th>
                    <th className="title">Quantity</th>
                    <th className="title">Application Date</th>
                    <th className="title">Mode of export</th>
                    <th className="title">npx Form Number</th>
                    <th className="title">price Per Barrel</th>
                    <th className="title">Vessel Imo No.</th>
                    <th className="title">period Covered</th>
                    <th className="title">FOB Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="main-header__sub-menu" data-column="product">
                      {this.state.verifier.productDescription}
                    </td>
                    <td
                      className="main-header__sub-menu"
                      data-column="quantity"
                    >
                      {this.state.verifier.quantity}
                    </td>
                    <td
                      className="main-header__sub-menu"
                      data-column="application"
                    >
                      {this.state.verifier.updatedAt}
                    </td>
                    <td
                      className="main-header__sub-menu"
                      data-column="mode_of_export"
                    >
                      {this.state.verifier.modeOfExport}
                    </td>
                    <td
                      className="main-header__sub-menu"
                      data-column="mode_of_export"
                    >
                      {this.state.verifier.npxFormNo}
                    </td>
                    <td
                      className="main-header__sub-menu"
                      data-column="vessel_imo_no"
                    >
                      {this.state.verifier.pricePerBarrel}
                    </td>
                    <td
                      className="main-header__sub-menu"
                      data-column="mode_of_export"
                    >
                      {this.state.verifier.vesselName}
                    </td>
                    <td
                      className="main-header__sub-menu"
                      data-column="name_of_vessel"
                    >
                      {this.state.verifier.periodCovered}
                    </td>
                    <td
                      className="main-header__sub-menu"
                      data-column="name_of_vessel"
                    >
                      {this.state.verifier.fobValue}
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <h2>Loading Permit Details...</h2>
            )}
          </div>

          <div className="parent-container">
            <div className="child1">
              <div className="features-card">
                <div className="advert-card">
                  <div class="consignee-details">
                    <div className="advertcard">
                      {this.state.verifier ? (
                        <>
                          <div className="permit-features">CONSIGNEE</div>
                          <div className="features-house">
                            <div className="features-title">Name</div>
                            <div className="advert-detail-container">
                              <p className="features-desc">
                                {this.state.verifier.consigneeName}
                              </p>
                            </div>
                          </div>
                          <div className="features-house">
                            <div className="features-title">Phone</div>
                            <div className="advert-detail-container">
                              <p className="features-desc">
                                {this.state.verifier.consigneePhoneNumber}
                              </p>
                            </div>
                          </div>
                          <div className="features-house">
                            <div className="features-title">Email</div>
                            <div className="advert-detail-container">
                              <p className="features-desc">
                                {this.state.verifier.consigneeEmail}
                              </p>
                            </div>
                          </div>
                          <div className="features-house">
                            <div className="features-title">Adress</div>
                            <div className="advert-detail-container">
                              <p className="features-desc">
                                {this.state.verifier.consigneeAddress}
                              </p>
                            </div>
                          </div>
                        </>
                      ) : (
                        <h2>Loading Consignee Details</h2>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="child2">
              <div className="desc-title">Action - Comment</div>
              <form className="checker-form">
                <textarea
                  type="text"
                  id="checkerdescription"
                  name="advertdescription"
                  placeholder="Advert Description"
                />
                <div className="advert-submit">
                  <span>
                    <button className="apply">Decline Permit</button>
                  </span>
                  <span>
                    <button className="click">Verify Permit</button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default VerifierManagePermit;
