import React from 'react'

const ManagePermitModal = () => {
    return (
        <div>
            Manage Permits
        </div>
    )
}

export default ManagePermitModal
