import React from 'react';

import {ExportersData} from '../data/ExportersData';

const ExportersTable = () => {
    const companies = (ExportersData);
    return (
        <>
            <table>
                <thead>
                    <tr>
                        <th className="table-head" data-column="S/N">S/N</th>
                        <th className="table-head" data-column="product">Company</th>
                        <th className="table-head" data-column="permitNumber">Reg. No.</th>
                        <th className="table-head" data-column="applicationDate">Representative</th>
                        <th className="table-head" data-column="status">Email</th>
                        <th className="table-head" data-column="consignee">Location</th>
                    </tr>
                </thead>
                <tbody>
                    {companies.map(( item, index ) => {
                        return (
                            <tr key={index}>
                            <td>{item.id}</td>
                            <td>{item.company}</td>
                            <td>{item.reg}</td>
                            <td>{item.reps}</td>
                            <td>{item.email}</td>
                            <td>{item.location}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            
        </>
    )
}

export default ExportersTable;