import React from 'react';

 const PendingPermitsData = [
    {
        id: 1,
        adminRole: "Verifier",
        idNo: 'BC1243PE',
        dateAppointed: '11/02/2021',
        phone: '08123456789',
        name: 'Alhaji Musa Tanko',
        location: 'Enugu'
    },
    {
        id: 2,
        adminRole: "Verifier",
        idNo: 'GI5541JJ',
        dateAppointed: '02/02/2021',
        phone: '08123456789',
        name: 'Shuan Shi',
        location: 'Kaduna'
    },
    {
        id: 3,
        adminRole: "Verifier",
        idNo: 'AJ0976DE',
        dateAppointed: '08/01/2021',
        phone: '08123456789',
        name: 'Mr. James Vendi',
        location: 'Yola'
    },
    {
        id: 4,
        adminRole: "Verifier",
        idNo: 'GK4366SE',
        dateAppointed: '13/01/2021',
        phone: '08123456789',
        name: 'Mr. James Vendi',
        location: 'Delta'
    },
    {
        id: 5,
        adminRole: "Recommender",
        idNo: 'AK4700NA',
        dateAppointed: '27/12/2020',
        phone: '08123456789',
        name: 'Dr. Noah Wayne',
        location: 'Porthacourt'
    },
    {
        id: 6,
        adminRole: "Recommender",
        idNo: 'BC1243PE',
        dateAppointed: '11/02/2021',
        phone: '08123456789',
        name: 'Alhaji Musa Tanko',
        location: 'Lagos'
    },
    {
        id: 7,
        adminRole: "Recommender",
        idNo: 'GI5541JJ',
        dateAppointed: '02/02/2021',
        phone: '08123456789',
        name: 'Shuan Shi',
        location: 'Abuja'
    },
    {
        id: 8,
        adminRole: "Recommender",
        idNo: 'AJ0976DE',
        dateAppointed: '08/01/2021',
        phone: '08123456789',
        name: 'Mr. James Vendi',
        location: 'Gombe'
    },
    {
        id: 9,
        adminRole: "Recommender",
        idNo: 'GK4366SE',
        dateAppointed: '13/01/2021',
        phone: '08123456789',
        name: 'Mr. James Vendi',
        location: 'Lokoja'
    },
    {
        id: 10,
        adminRole: "Recommender",
        idNo: 'AK4700NA',
        dateAppointed: '27/12/2020',
        phone: '08123456789',
        name: 'Dr. Noah Wayne',
        location: 'Kano'
    },
];

export default PendingPermitsData;