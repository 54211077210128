import React, { useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import {getAllPermits} from '../../actions/permits';
import { useDispatch } from 'react-redux';

export const AllPermits = () => {
    const permits = useSelector((state) => state.permits);

    const dispatch = useDispatch();
    
    useEffect(() => {
        const permits = getAllPermits();
        permits();
        dispatch(getAllPermits());
    }, []);
    console.log(permits);

    return (
        <>
        {!permits.length ? <h6>You Have No Export Activity</h6> : 
            <table>
                <thead>
                    <tr>
                        <th className="table-head" data-column="S/N">S/N</th>
                        <th className="table-head" data-column="product">Product</th>
                        <th className="table-head" data-column="exporter">Quantity</th>
                        <th className="table-head" data-column="permitNumber">Application Date</th>
                        <th className="table-head" data-column="status">Consignee Name</th>
                        <th className="table-head" data-column="consignee">Consignee Location</th>
                    </tr>
                </thead>
                <tbody>
                    {permits.map(( item, index, ) => {
                        return (
                            <tr key={item._id}>
                            <td>{index + 1}</td>
                            <td>{item.productDescription}</td>
                            <td>{item.quantity}</td>
                            <td>{item.updatedAt}</td>
                            <td>{item.consigneeName}</td>
                            <td>{item.consigneeCity}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        }
        </>
    )
}