import React from 'react';

const ExportReturnsData = [
    {
        id: 1,
        product: 'Crude Oil',
        permitNo: 'BC1243PE',
        returnStatus: 'approved',
        consignee: 'Alhaji Musa Tanko',
        cbnStatus: 'pending',
        date: '22/12/2020',
        amount: '31,785,000'
    },
    {
        id: 2,
        product: 'Crude Oil',
        permitNo: 'BC1243PE',
        returnStatus: 'approved',
        consignee: 'Alhaji Musa Tanko',
        cbnStatus: 'pending',
        date: '22/12/2020',
        amount: '31,785,000'
    },
    {
        id: 3,
        product: 'Crude Oil',
        permitNo: 'BC1243PE',
        returnStatus: 'pending',
        consignee: 'Alhaji Musa Tanko',
        cbnStatus: 'pending',
        date: '22/12/2020',
        amount: '31,785,000'
    },
    {
        id: 4,
        product: 'Crude Oil',
        permitNo: 'BC1243PE',
        returnStatus: 'pending',
        consignee: 'Alhaji Musa Tanko',
        cbnStatus: 'pending',
        date: '22/12/2020',
        amount: '31,785,000'
    },
    {
        id: 5,
        product: 'Crude Oil',
        permitNo: 'BC1243PE',
        returnStatus: 'pending',
        consignee: 'Alhaji Musa Tanko',
        cbnStatus: 'pending',
        date: '22/12/2020',
        amount: '31,785,000'
    },
    {
        id: 6,
        product: 'Crude Oil',
        permitNo: 'BC1243PE',
        returnStatus: 'approved',
        consignee: 'Alhaji Musa Tanko',
        cbnStatus: 'pending',
        date: '22/12/2020',
        amount: '31,785,000'
    },
    {
        id: 7,
        product: 'Crude Oil',
        permitNo: 'BC1243PE',
        returnStatus: 'approved',
        consignee: 'Alhaji Musa Tanko',
        cbnStatus: 'pending',
        date: '22/12/2020',
        amount: '31,785,000'
    },
    {
        id: 8,
        product: 'Crude Oil',
        permitNo: 'BC1243PE',
        returnStatus: 'approved',
        consignee: 'Alhaji Musa Tanko',
        cbnStatus: 'pending',
        date: '22/12/2020',
        amount: '31,785,000'
    },
    {
        id: 9,
        product: 'Crude Oil',
        permitNo: 'BC1243PE',
        returnStatus: 'approved',
        consignee: 'Alhaji Musa Tanko',
        cbnStatus: 'pending',
        date: '22/12/2020',
        amount: '31,785,000'
    },
    {
        id: 10,
        product: 'Crude Oil',
        permitNo: 'BC1243PE',
        returnStatus: 'approved',
        consignee: 'Alhaji Musa Tanko',
        cbnStatus: 'pending',
        date: '22/12/2020',
        amount: '31,785,000'
    },
    {
        id: 11,
        product: 'Crude Oil',
        permitNo: 'BC1243PE',
        returnStatus: 'approved',
        consignee: 'Alhaji Musa Tanko',
        cbnStatus: 'pending',
        date: '22/12/2020',
        amount: '31,785,000'
    },
    {
        id: 12,
        product: 'Crude Oil',
        permitNo: 'BC1243PE',
        returnStatus: 'approved',
        consignee: 'Alhaji Musa Tanko',
        cbnStatus: 'pending',
        date: '22/12/2020',
        amount: '31,785,000'
    },
];

export default ExportReturnsData;