import { AUTH } from "../constants/actionTypes";

import * as api from "../api/index.jsx";

export const signup = (signUpData, history) => async (dispatch) => {
  try {
    //expoter registration
    const { data } = await api.signUp(signUpData);
    dispatch({ type: AUTH, payload: data });
    history.push("/exporterprofile"); //push to exporter's profile page
  } catch (error) {
    console.log(error);
  }
};

export const signin = (signInData, history) => async (dispatch) => {
  api
    .signIn(signInData)
    .then(({ data }) => {
      dispatch({
        type: AUTH,
        payload: { user: data.data, token: data.token },
      });
      history.push("/exporterdashboard"); //push to esporter dashboard
    })
    .catch(() => {
      //   Handle error here
    });
};

export const adminsignin = (adminSignInData, history) => async (dispatch) => {
  api
    .adminsignin(adminSignInData)
    .then(({ data }) => {
      console.log(data);
      dispatch({
        type: AUTH,
        payload: { user: data.data, token: data.token },
      });
      console.log("admin loged in");
      history.push("/cedadmindashboard"); //push to esporter dashboard
    })
    .catch(() => {
      //   Handle error here
    });
};

export const verifiersignin = (adminSignInData, history) => async (dispatch) => {
  api
    .adminsignin(adminSignInData)
    .then(({ data }) => {
      console.log(data);
      dispatch({
        type: AUTH,
        payload: { user: data.data, token: data.token },
      });
      console.log("admin loged in");
      history.push("/verifierdashboard"); //push to esporter dashboard
    })
    .catch(() => {
      //   Handle error here
    });
};

export const recommendersignin = (adminSignInData, history) => async (dispatch) => {
  api
    .adminsignin(adminSignInData)
    .then(({ data }) => {
      console.log(data);
      dispatch({
        type: AUTH,
        payload: { user: data.data, token: data.token },
      });
      console.log("admin loged in");
      history.push("/recommenderdashboard"); //push to esporter dashboard
    })
    .catch(() => {
      //   Handle error here
    });
};


export const createCEDAdmin = (adminRegData, history) => async (dispatch) => {
  try {
    //expoter registration
    const { data } = await api.createCEDAdmin(adminRegData);
    dispatch({ type: AUTH, payload: data });
    history.push("/viewcedprofile"); //push to exporter's profile page
  } catch (error) {
    console.log(error);
  }
};