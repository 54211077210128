import React from 'react';
import { Link } from 'react-router-dom';

import ExportReturnsData from '../../data/ExportReturnsData';

function ExportReturnsCard() {
    const returns = ExportReturnsData;

    return (
        <div className="returnscard-main">
        {returns.map(( item, index ) => {
            return(
                <div classNAme="returnscard" key={index}>
                    <div className="returns-house">
                        <div className="returns-detail-container">
                            <div>
                                <div><span className="title">Product: </span><span>{item.product}</span></div>
                                <div><span className="title">Permit Number: </span><span>{item.permitNo}</span></div>
                                <div><span className="title">Consignee: </span><span>{item.consignee}</span></div>
                            </div>
                            <div className="house">
                                <div className="room">
                                    <div>
                                        <span className="title">Amount: </span>
                                        <span>N{item.amount}</span>
                                    </div>
                                    <div>
                                        <span className="title">Return Status: </span>
                                        <span className={item.returnStatus}>{item.returnStatus}</span>
                                    </div>
                                    <div>
                                        <span className="title">NXP Status: </span>
                                        <span className={item.cbnStatus}>{item.cbnStatus}</span>
                                    </div>
                                    <div>
                                        <span className="title">Date: </span>
                                        <span>{item.amount}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })}
        </div>
    )
}

export default ExportReturnsCard;
