import React from 'react';

import CEDAdminData from '../../data/CEDAdminData';

const CEDAdminTable = () => {
    const admins = CEDAdminData;
    return (
       <>
            <table>
                <thead>
                    <tr>
                        <th className="table-head" data-column="S/N">S/N</th>
                        <th className="table-head" data-column="name">Name</th>
                        <th className="table-head" data-column="idNo">ID Number</th>
                        <th className="table-head" data-column="role">Role</th>
                        <th className="table-head" data-column="phone">Phone Number</th>
                        <th className="table-head" data-column="location">Location</th>
                        <th className="table-head" data-column="date">Date Asigned</th>
                    </tr>
                </thead>
                <tbody>
                    {admins.map(( admin, index ) => {
                        return (
                            <tr>
                            <td>{admin.id}</td>
                            <td>{admin.name}</td>
                            <td>{admin.idNo}</td>
                            <td>{admin.adminRole}</td>
                            <td>{admin.phone}</td>
                            <td>{admin.location}</td>
                            <td>{admin.dateAppointed}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
}

export default CEDAdminTable
