import React from 'react';
import { useSelector } from 'react-redux';

const PermitCompanyDetails = () => {
    const exporter = useSelector((state) => state.auth.user);

    return (
        <div>
            <div className="company-data">
            <div><span className="main-header__sub-menu">Export Company Details</span></div>
                <div className="house">
                    <div className="company-details">
                        <div>
                            <span className="title">Company Name: </span>
                            <span className="apply">{exporter?.companyName}</span>
                        </div>
                        <div>
                            <span className="title">Email: </span>
                            <span className="apply">{exporter?.email}</span>
                        </div>
                        <div>
                            <span className="title">RC No: </span>
                            <span className="apply">{exporter?.rcNumber}</span>
                        </div>
                        <div>
                            <span className="title">Phone Number: </span>
                            <span className="apply">{exporter?.phoneNumber}</span>
                        </div>
                        <div>
                            <span className="title">postal Code: </span>
                            <span className="apply">{exporter?.postalCode}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PermitCompanyDetails
