import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { PendingPermitsTable } from '../Permits/PendingPermitsTable';
import Searchbar from '../Searchbar';


export default () => (
  <Tabs>
    <TabList>
        <Tab>Permits Pending Verification</Tab>
        <Tab>Permits Pending Recommendation</Tab>
        <Tab>Permits Pending Endorsement</Tab>
        <Tab>Permits Pending Approval</Tab>
    </TabList>

    <TabPanel>
        <Searchbar />
        <h3>Awaiting Verification</h3>
        <PendingPermitsTable />
    </TabPanel>
    <TabPanel>
        <Searchbar />
        <h3>Awaiting Recommendation</h3>
        <PendingPermitsTable />
    </TabPanel>
    <TabPanel>
        <Searchbar />
        <h3>Awaiting Endorsement</h3>
        <PendingPermitsTable />
    </TabPanel>
    <TabPanel>
        <Searchbar />
        <h3>Awaiting Approval</h3>
        <PendingPermitsTable />
    </TabPanel>
  </Tabs>
);