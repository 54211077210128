import React, {useState} from 'react';
import '../../dist/css/main.css';

import ExporterSidebar from '../../components/sidenav/ExporterSidenav'
import ExporterOverviewCard from '../../components/overviewCards/ExporterOverviewCards';
import Searchbar from '../../components/Searchbar';
import { ExportTable } from '../../components/ExportTable';
import DashBoardHeader from '../../components/DashBoardHeader';

const PermitDelivered = () => {

    return (
        <div className="grid-container">
            <DashBoardHeader />
            <ExporterSidebar />
            <main className="main">
                <div className="main-header">
                    <div className="main-header__heading">
                        <span className="main-header__menu">Dashboard | </span>
                        <span className="main-header__sub-menu">Home</span>
                    </div>
                </div>
                <Searchbar />
                <div className="company-details">
                    <h3>You Have No Delivered Product</h3>
                </div>
            </main>
            <div className="footer"></div>
        </div>
    )
}

export default PermitDelivered;
