import React, {useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from 'react-router-dom';

import * as actionType from '../../constants/actionTypes';
import '../../dist/css/main.css';

import Logo from '../../dist/images/coatofarm.png';
import HomeIcon from '../../dist/images/home.svg';

function Sidenav() {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('data')));
    const exporter = useSelector((state) => state.auth.user);
    console.log(exporter);
    const dispatch = useDispatch();
    const history = useHistory();
  
    
  const logout = () => {
    dispatch({ type: actionType.LOGOUT });
    history.push('/');
    setUser(null);
  };
    
    return (
        <nav className="sidenav">
            <img src={Logo} className="dashboard-logo" alt="logo"/>
            <ul className="sidenav__list">
                <li className="company-identity">
                    <h4>{exporter?.companyName}</h4>
                    <p>{exporter?.account.toUpperCase()}</p>
                </li>
                <Link to="/exporterdashboard">
                    <li className="sidenav__list-item active-tab">
                        <img src={HomeIcon} className="sidenav-icon" alt="homeicon"/> 
                        <span>Home</span>
                    </li>
                </Link>
                <Link to="/exporterprofile">
                    <li className="sidenav__list-item">
                        <img src={HomeIcon} className="sidenav-icon" alt="homeicon"/> 
                        <span>Profile</span>
                    </li>
                </Link>
                <Link to="/permitapplication">
                    <li className="sidenav__list-item">
                        <img src={HomeIcon} className="sidenav-icon" alt="homeicon"/> 
                        <span>New Permit Application</span>
                    </li>
                </Link>
                <Link to="/exporterreturns">
                    <li className="sidenav__list-item">
                        <img src={HomeIcon} className="sidenav-icon" alt="homeicon"/> 
                        <span>Export Data Returns</span>
                    </li>
                </Link>
                    
                <div className="nav-user-profile">
                <li className="sidenav__logout-item"  onClick={logout} >
                    <img src={HomeIcon} className="sidenav-icon" alt="homeicon"/> 
                    Log out
                </li> 
                </div>
            </ul>
        </nav>
    )
}

export default Sidenav

