import React from 'react';
import Logo from '../dist/icons/logo.png';

const CompanyDocuments = (props) => {
    return (
        <>
            <div className="child1">
            <h3>Company Documents</h3>
                <div>
                <div className="inner-row">
                    <div className="inner-column">
                        <div className="title">CAC Document: <span className="reset">view</span></div>
                    </div>
                    <div className="inner-column">
                        <div className="title">Company Article and memorandum of association: <span className="reset">view</span></div>
                    </div>
                </div>
                <div className="inner-row">
                    <div className="inner-column">
                        <div className="title">DPR Lincense: <span className="reset">view</span></div>
                    </div>
                    <div className="inner-column">
                        <div className="title">Bank Reference: <span className="reset">view</span></div>
                    </div>
                </div>
                <div className="inner-row">
                    <div className="inner-column">
                        <div className="title">WMD Certificate: <span className="reset">view</span></div>
                    </div>
                    <div className="inner-column">
                        <div className="title">Tax clearance Certificate: <span className="reset">view</span></div>
                    </div>
                </div>
                {/* <div className="inner-row">
                    <div className="inner-column">
                        <div className="title">Nigerian Trade permit: <span className="reset">view</span></div>
                    </div>
                    <div className="inner-column">
                        <div className="title">Nigerian Trade permit: <span className="reset">view</span></div>
                    </div>
                </div> */}
                <div className="user-profile-btn">
                    <button class="click">Update Comapny Documents</button>
                </div>
                </div>
            </div> 
        </>
    )
}

export default CompanyDocuments
