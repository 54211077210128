import React from 'react';

const GuidelineModal = () => {
    return (
        <div className="guideline">
            <h2 className="main-title">FEDERAL MINISTRY OF INDUSTRY TRADE AND INVESTMENT</h2>
            <h3 className="sub-title">COMMODITIES AND EXPORT DEPARTMENT</h3>
            <h4 >GUIDELINES FOR THE EXPORTATION OF OIL AND GAS AND OTHER PETROLEUM PRODUCTS FROM NIGERIA</h4>
            <h5 className="list-title">A. ELIGIBILITY</h5>
            <p>The following categories of companies may apply for E-PERMIT to Export Crude Oil, Liquefied Natural Gas,
                Liquefied Petroleum Gas, Condensate, Refined Products, Lubricants, Grease, etc.
            </p>
            <ul>
                <li>1. Nigerian National Petroleum Corporation (NNPC)</li>
                <li>2. International Oil Companies (IOCs)</li>
                <li>3. Pipelines and Products Marketing Company (PPMC) Ltd</li>
                <li>4. Indigenous Oil Producing Companies</li>
                <li>5. Major Oil and Gas Marketing Companies</li>
                <li>6. Independent Petroleum Marketing Companies (IPMAN or DAPPMA Companies) with current DPR license for receptacles/Storage facilities (i.e. jetties/depots)</li>
            </ul>
            <h5 className="list-title">B. CONDITIONS FOR EXPORTATION OF OIL AND GAS AND OTHER PETROLEUM PRODUCTS</h5>
            <ul>
                <li>1. Application for Export Clearance E-Permit made on company's letter headed paper.</li>
                <li>2. Duly completed Application Form E001 attached with a copy of:</li>
                <div>
                <li  className="sub-list">a. Certificate of Incorporation of the applicant's company.</li>
                <li  className="sub-list">b. Company Article and Memorandum of Association</li>
                <li  className="sub-list">c. Current Production/Storage/Sales license issued by DPR.</li>
                <li  className="sub-list">d. Current Weights and Measures Department certificate of conformity for the Fiscal Meters, Gauges, and all Custody Transfer Weighing & Measuring instruments at the terminal(s) to be used for the export.</li>
                <li  className="sub-list">e. Original Bank reference with committed and explicit statements</li>
                <li  className="sub-list">f. 3-Years Tax Clearance Certificate</li>
                </div>
                <li>3. Every export Permit shall have validity duration of 90 days from the date of issue.</li>
                <li>Each exporter of Oil and Gas shall be required to complete Form DV001 on the previous EXPORTATION for which permit was granted, along with copies of all relevant invoices and shipping documents, before any subsequent application for an Export Permit can be approved. Such documents shall include</li>
                <li className="sub-list">a. Evidence of Payment of Monitoring Fees</li>
                <li className="sub-list">b. Particulars of NXP Form</li>
                <li className="sub-list">c. Relevant Invoices</li>
                <li className="sub-list">d. Bill of Lading</li>
                <li className="sub-list">e. Photocopy of Approved Form “M”</li>
                <li className="sub-list">f. Detail cargo information, such as direct shipment, ship to ship, name of mother vessel, certificate of quality, last port of call etc.</li>
            </ul>
        </div>
    )
}

export default GuidelineModal;
