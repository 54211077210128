import React, { useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from "react-hook-form";

import { signup } from '../actions/auth';
import Navigation from '../components/navigation';

const initialState = { 
  email: '',
  password: '',
  tin: '',
 }

const SignUp = () => {
  const [signUpData, setSignUpData] = useState(initialState);
  const [showPassword, setShowPassword] = useState(false);

  const { register, errors, handleSubmit, watch } = useForm({});
  const password = useRef({});
  password.current = watch("password", "");
  
  const dispatch = useDispatch();
  const history = useHistory();
  
  const handleFormSubmit = (e) => {
    e.preventDefault();
    dispatch(signup( signUpData, history ));
    console.log(signUpData);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setSignUpData({ ...signUpData, [e.target.name] : e.target.value });
  };

  const handleShowPassword = () => setShowPassword((prevShowPassword) => !prevShowPassword);

    return (
      <>
      <Navigation />
        <div id="contact">
          <div className="container">
            <div className="col-md-8">
              <div className="row">
                <div className="section-title">
                  <h2>Sign Up</h2>
                  <p>Welcome, kindly fill in the form below to register your company.</p>
                </div>
                <form name="signUpForm" id="signUpForm" onSubmit={handleFormSubmit} noValidate>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Company's Email"
                          onChange={handleChange}
                        />
                        {errors.password && <p>{errors.password.message}</p>}
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="tin"
                          name="tin"
                          className="form-control"
                          placeholder="Company's Tax Identification Number"
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          name="password"
                          className="form-control"
                          placeholder="Password"
                          onChange={handleChange}
                          ref={register({
                          required: "You must specify a password",
                          minLength: {
                            value: 8,
                            message: "Password must have at least 8 characters"
                            }
                          })}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type={showPassword ? "text" : "password"}
                          id="password_repeat"
                          name="password_repeat"
                          className="form-control"
                          placeholder="Repeat Password"
                          onChange={handleChange}
                          ref={register({
                          validate: value =>
                            value === password.current || "The passwords do not match"
                          })}
                        />
                        {errors.password_repeat && <p>{errors.password_repeat.message}</p>}
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <button type="submit" className="btn btn-custom btn-lg" onClick={handleSubmit}>
                    Register
                  </button>
                {/* <span><Link to="/exporterdashboard" className="btn btn-custom btn-lg">EXPORTER</Link></span>
                <span><Link to="/cedadmindashboard" className="btn btn-custom btn-lg">CED ADMIN</Link></span>
                <span><Link to="/verifierdashboard" className="btn btn-custom btn-lg">E PERMIT CHECKER</Link></span>
                <span><Link to="/recommenderdashboard" className="btn btn-custom btn-lg">E PERMIT RECOMMENDER</Link></span>
                <span><Link to="/recommenderdashboard" className="btn btn-custom btn-lg">E PERMIT ENDORSEMENT</Link></span>                 */}
                </form>
              </div>
            </div>
            <div className="col-md-3 col-md-offset-1 contact-info">
              <div className="contact-item">
                <h3>Contact Info</h3>
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Address
                  </span>
                  Abuja, Nigeria
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Phone
                  </span>{" "}
                  080123456789
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  contact@trademetrology@.com
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="social">
                  <ul>
                    <li>
                    <Link to="/adminlogin">CED ADMIN</Link>
                    </li>
                    <li>
                        <Link to="/checkerlogin">E-PERMIT CHECKER</Link>
                    </li>
                    <li>
                    <Link to="/recommenderlogin">RECOMMENDER</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
}

export default SignUp
