import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import Modal from 'react-modal';

import COA from '../dist/images/coatofarm.png';
import GuidelineModal from "./modals/GuidelineModal";

export const Header = () => {
  const exporter = useSelector((state) => state.auth.user);
  const [modalOpen, setMOdalOpen] = useState(false);

    return (
      <header id="header">
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                  <img src={COA} alt="coat-of-arm"/>
                  <h1>FEDERAL MINISTRY OF INDUSTRY, TRADE AND INVESTMENT</h1>
                  <h4>Commodities and Export Department</h4>
                  <p>enhancing service delivery in a manner that will stimulate the growth of the domestic economy for self-reliance.</p>
                  <Link to={exporter ? "/signUp" : "/"}
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    APPLY FOR EXPORT PERMIT
                  </Link>{" "}
                  <h4> OR READ THE </h4>                  
                  <div 
                  className="btn btn-custom btn-lg page-scroll" 
                  onClick={() => setMOdalOpen(true)} 
                  > EXPORT PERMIT APPLICATION GUIDELINES</div> {" "}
                </div>
                  <Modal isOpen={modalOpen} onRequestClose={() => setMOdalOpen(false)}>
                  <GuidelineModal />
                  <div className="btn btn-custom btn-lg page-scroll" onClick={() => setMOdalOpen(false)} >Close</div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }

export default Header;
