import { FETCH_EXPORTER_PERMITS, CREATE_PERMIT } from '../constants/actionTypes';

export default (permits = [], action) => {
  switch (action.type) {
    case FETCH_EXPORTER_PERMITS:
      return action.payload.data;
    case CREATE_PERMIT:
      return permits.map((permit) => (permit.id === action.payload.id ? action.payload : permit));
    default:
      return permits;
  }
};

