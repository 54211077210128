import React from 'react';
import { Link } from 'react-router-dom';

import Pending from '../../dist/icons/pending.svg';
import Delivery from '../../dist/icons/delivery.svg';
import Done from '../../dist/icons/done.svg';
import Next from '../../dist/icons/next.svg';

function Cards() {
    return (
        <div className="main-overview">
        <div className="overviewcard">
            <div className="overviewcard__icon blue-bg">
                <img src={Pending} className="icon-group" alt="groupicon"/> 
            </div>
            <div className="overviewcard__info">
                <div className="title">Pending Permit(s)</div>
                <div className="figure">0 
                    <span>
                        <Link to="/pendingpermits" className="click">view</Link>
                    </span>
                </div>
            </div>
        </div>
        <div className="overviewcard">
            <div className="overviewcard__icon green-bg">
                <img src={Delivery} className="icon-group" alt="groupicon"/>
            </div>
            <div className="overviewcard__info">
                <div className="title">On Transit Product(s)</div>
                <div className="figure">0 
                    <span>
                        <Link to="/ontransitproducts" className="click">view</Link>
                    </span>
                </div>
            </div>
        </div>
        <div className="overviewcard">
            <div className="overviewcard__icon orange-bg">
                <img src={Done} className="icon-group" alt="groupicon"/>
            </div>
            <div className="overviewcard__info">
                <div className="title">Delivered Products(s)</div>
                <div className="figure">0 
                    <span>
                        <Link to="/deliveredproducts" className="click">view</Link>
                    </span>
                </div>
            </div>
        </div>
        <div className="overviewcard">
            <div className="overviewcard__icon purple-bg">
                <img src={Next} className="icon-group" alt="PromotionIcon"/>
            </div>
            <div className="overviewcard__info">
                <div className="title"><span className="apply">Apply for E-Permit</span></div>
                <Link to="/permitapplication" className="figure"><span className="click">Click Here</span></Link>
            </div>
        </div>
        </div>
    )
}

export default Cards
