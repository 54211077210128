import React, { useEffect} from 'react';
import { useSelector } from "react-redux";
import {getPermits} from '../actions/permits';
import { useDispatch } from 'react-redux'


export const ExportTable = () => {
    const exportPermits = useSelector((state) => state.permits);
    const id = useSelector((state) => state.auth.user._id);
    console.log(id);
    const checker = useSelector((state) => state.auth.user);
    console.log(checker);

    const dispatch = useDispatch();
    
    useEffect(() => {
        const permits = getPermits();
        permits();
        dispatch(getPermits(id));
    }, []);

    return (
        <>
        {!exportPermits.length ? <h6>You Have No Export Activity</h6> : 
            <table>
                <thead>
                    <tr>
                        <th className="table-head" data-column="S/N">S/N</th>
                        <th className="table-head" data-column="product">Product</th>
                        <th className="table-head" data-column="permitNumber">Quantity</th>
                        <th className="table-head" data-column="applicationDate">Application Date</th>
                        <th className="table-head" data-column="status">Consignee Name</th>
                        <th className="table-head" data-column="consignee">Consignee Location</th>
                    </tr>
                </thead>
                <tbody>
                    {exportPermits.map(( item, index ) => {
                        return (
                            <tr>
                            <td>{index + 1}</td>
                            <td>{item.productDescription}</td>
                            <td>{item.quantity}</td>
                            <td>{item.updatedAt}</td>
                            <td>{item.consigneeName}</td>
                            <td>{item.consigneeCity}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>}
        </>
    )
}
