import React, {useState} from 'react';
import '../../dist/css/main.css';


import OverviewCards from '../../components/overviewCards/OverviewCards';
import Searchbar from '../../components/Searchbar';
import CEDSidenav from '../../components/sidenav/CEDSidenav';
import { PendingPermitsTable } from '../../components/Permits/PendingPermitsTable';
import DashBoardHeader from '../../components/DashBoardHeader';

const CEDExportEnquiries = () => {

    return (
        <div className="grid-container">
            <DashBoardHeader />
            <CEDSidenav />
            <main className="main">
                <div className="main-header">
                    <div className="main-header__heading">
                        <span className="main-header__menu">Dashboard | </span>
                        <span className="main-header__menu">Export Management | </span>
                        <span className="main-header__sub-menu">Enquiries</span>
                    </div>
                </div>
                <Searchbar />
                <h3>Export Enquiries</h3>
                <div className="company-details">
                <h2>No Export Enquiries Available...</h2>
                </div>
            </main>
            <div className="footer"></div>
        </div>
    )
}

export default CEDExportEnquiries;
