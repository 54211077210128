import React, {useState} from 'react';
import '../../dist/css/main.css';


import Searchbar from '../../components/Searchbar';
import CEDSidenav from '../../components/sidenav/CEDSidenav';
import DashBoardHeader from '../../components/DashBoardHeader';
import ExportersTable from '../../components/ExportersTable';

const CEDExportCompanies = () => {

    return (
        <div className="grid-container">
            <DashBoardHeader />
            <CEDSidenav />
            <main className="main">
                <div className="main-header">
                    <div className="main-header__heading">
                        <span className="main-header__menu">Dashboard | </span>
                        <span className="main-header__menu">Export Management | </span>
                        <span className="main-header__sub-menu">Exporters</span>
                    </div>
                </div>
                <Searchbar />
                <h3>Export Companies</h3>
                <ExportersTable />
            </main>
            <div className="footer"></div>
        </div>
    )
}

export default CEDExportCompanies;
