import React from "react";
import { Switch, Route } from "react-router-dom";

import Home from "./pages/Home";
import PermitTracking from "./pages/PermitTracking";
import PermitGuidelines from "./pages/PermitGuidelines";
import PhotoGallery from "./pages/PhotoGallery";
import SignUp from "./auth/SignUp";
import Login from "./auth/Login";
import AdminLogin from "./auth/AdminLogin";
import CheckerLogin from "./auth/CheckerLogin";
import RecommenderLogin from "./auth/RecommenderLogin";
import PasswordReset from "./auth/PasswordReset";

import ExporterDashboard from "./dashboard/exporters/ExporterDashboard";
import ExporterProfile from "./dashboard/exporters/ExporterProfile";
import UpdateCompanyProfile from "./dashboard/exporters/UpdateCompanyProfile";
import UpdateCompanyRepProfile from "./dashboard/exporters/UpdateCompanyRep";
import ExporterReturns from "./dashboard/exporters/ExporterReturns";
import PermitApplication from "./dashboard/exporters/PermitApplication";
import PendinPermits from "./dashboard/exporters/PendingPermits";
import PermitOnTransit from "./dashboard/exporters/PermitOnTransit";
import PermitDelivered from "./dashboard/exporters/PermitDelivered";

import CEDAdminDashboard from "./dashboard/CED/CEDAdminDashboard";
import PendingPermits from "./dashboard/CED/PendingPermits";
import PendingRecommendation from "./dashboard/CED/PendingRecommendation";
import PendingEndorsement from "./dashboard/CED/PendingEndosement";
import PendingApproval from "./dashboard/CED/PendingApproval";
import ViewCEDProfile from "./dashboard/CED/ViewCEDProfile";
import CreateCEDProfile from "./dashboard/CED/CreateCEDProfile";
import PendingVerification from "./dashboard/CED/PendingVerification";
import CEDExportReturns from "./dashboard/CED/CEDExportReturns";
import CEDExportReports from "./dashboard/CED/CEDExportReports";
import CEDExportEnquiries from "./dashboard/CED/CEDExportEnquiries";
import CEDExportCompanies from "./dashboard/CED/CEDExportCompanies";

import VerifierDashboard from "./dashboard/verifier/VerifierDashboard";
import VerifierExportCompanies from "./dashboard/verifier/VerifierExportCompanies";
import VerifierExportEnquiries from "./dashboard/verifier/VerifierExportEnquiries";
import VerifierExportReports from "./dashboard/verifier/VerifierExportReports";
import VerifierExportReturns from "./dashboard/verifier/VerifierExportReturns";

import RecommenderDashboard from "./dashboard/recommender/RecommenderDashboard";
import RecommenderExportCompanies from "./dashboard/recommender/RecommenderExportCompanies";
import RecommenderExportEnquiries from "./dashboard/recommender/RecommenderExportEnquiries";
import RecommenderExportReports from "./dashboard/recommender/RecommenderExportReports";
import RecommenderExportReturns from "./dashboard/recommender/RecommenderExportReturns";
import PrivateRoute from "./components/PrivateRoute";
import { connect } from "react-redux";

import CEDManagePermit from "./dashboard/CED/CEDManagePermit";
import VerifierManagePermit from "./dashboard/verifier/VerifierManagePermit";
import RecommenderManagePermit from "./dashboard/recommender/RecommenderManagePermit";

function App({ auth }) {
  return (
    <div>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/permittracking" component={PermitTracking} />
        <Route
          exact
          path="/applicationguideline"
          component={PermitGuidelines}
        />
        <Route exact path="/photogallery" component={PhotoGallery} />
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/adminlogin" component={AdminLogin} />
        <Route exact path="/checkerlogin" component={CheckerLogin} />
        <Route exact path="/recommenderlogin" component={RecommenderLogin} />
        <Route exact path="/passwordreset" component={PasswordReset} />

        <PrivateRoute
          exact
          path="/exporterdashboard"
          component={ExporterDashboard}
          authState={auth.user}
        />
        <PrivateRoute
          exact
          path="/exporterprofile"
          component={ExporterProfile}
          authState={auth.user}
        />
        <PrivateRoute
          exact
          path="/updatecompanyprofile"
          component={UpdateCompanyProfile}
        authState={auth.user} />
        <PrivateRoute
          exact
          path="/updatecompanyrepprofile"
          component={UpdateCompanyRepProfile}
        authState={auth.user} />
        <PrivateRoute exact path="/permitapplication" component={PermitApplication} authState={auth.user} />
        <PrivateRoute exact path="/exporterreturns" component={ExporterReturns} authState={auth.user} />
        <PrivateRoute exact path="/pendingpermits" component={PendinPermits} authState={auth.user} />
        <PrivateRoute exact path="/ontransitproducts" component={PermitOnTransit} authState={auth.user} />
        <PrivateRoute exact path="/deliveredproducts" component={PermitDelivered} authState={auth.user} />

        <PrivateRoute exact path="/cedadmindashboard" component={CEDAdminDashboard} authState={auth.user} />
        <PrivateRoute exact path="/cedpermitapplications" component={PendingPermits} authState={auth.user} />
        <PrivateRoute
          exact
          path="/permitspendingverification"
          component={PendingVerification}
        authState={auth.user} />
        <PrivateRoute
          exact
          path="/pendingrecommendation"
          component={PendingRecommendation}
        authState={auth.user} />
        <PrivateRoute
          exact
          path="/pendingendorsement"
          component={PendingEndorsement}
        authState={auth.user} />
        <PrivateRoute exact path="/pendingApproval" component={PendingApproval} authState={auth.user} />
        <PrivateRoute exact path="/viewcedprofile" component={ViewCEDProfile} authState={auth.user} />
        <PrivateRoute exact path="/createcedprofiles" component={CreateCEDProfile} authState={auth.user} />
        <PrivateRoute exact path="/cedreturns" component={CEDExportReturns} authState={auth.user} />
        <PrivateRoute exact path="/cedreports" component={CEDExportReports} authState={auth.user} />
        <PrivateRoute exact path="/cedenquiries" component={CEDExportEnquiries} authState={auth.user} />
        <PrivateRoute
          exact
          path="/cedexportcompanies"
          component={CEDExportCompanies}
        authState={auth.user} />

        <PrivateRoute exact path="/verifierdashboard" component={VerifierDashboard} authState={auth.user} />
        <PrivateRoute
          exact
          path="/verifierexportcompanies"
          component={VerifierExportCompanies}
        authState={auth.user} />
        <PrivateRoute
          exact
          path="/verifierenquiries"
          component={VerifierExportEnquiries}
        authState={auth.user} />
        <PrivateRoute
          exact
          path="/verifierreports"
          component={VerifierExportReports}
        authState={auth.user} />
        <PrivateRoute
          exact
          path="/verifierreturns"
          component={VerifierExportReturns}
        authState={auth.user} />

        <PrivateRoute
          exact
          path="/recommenderdashboard"
          component={RecommenderDashboard}
        authState={auth.user} />
        <PrivateRoute
          exact
          path="/recommenderexportcompanies"
          component={RecommenderExportCompanies}
        authState={auth.user} />
        <PrivateRoute
          exact
          path="/recommenderenquiries"
          component={RecommenderExportEnquiries}
        authState={auth.user} />
        <PrivateRoute
          exact
          path="/recommenderreports"
          component={RecommenderExportReports}
        authState={auth.user} />
        <PrivateRoute
          exact
          path="/recommenderreturns"
          component={RecommenderExportReturns}
        authState={auth.user} />

        <PrivateRoute exact path="/:verifier_id" component={VerifierManagePermit} authState={auth.user} />
        <PrivateRoute exact path="/:permit_id" component={CEDManagePermit} authState={auth.user} />
        <PrivateRoute exact path="/:recommender_id" component={RecommenderManagePermit} authState={auth.user} />
      </Switch>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(App);
