import React from 'react';


function Searchbar() {
    return (
        <div className="wrap">
            <form className="search-form" onsubmit="event.preventDefault();" role="search">
            <label htmlFor="search">Search export activities</label>
            <input id="search" type="search" placeholder="type to search..." autofocus required />
            <button type="submit">Search</button>    
            </form>
        </div>
    )
}

export default Searchbar
