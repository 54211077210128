import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createPermit } from '../../actions/permits';

const initialPermitState = {
  productDescription: '',
  quantity: '',
  fobValue: '',
  pricePerBarrel: '',
  npxFormNo: '',
  vesselName: '',
  periodCovered: '',
  modeOfExport: '',
  exportDate: '',
  unit: '',
  totalPrice: '',
  consigneeName: '',
  consigneeEmail: '',
  consigneePhoneNumber: '',
  consigneeAddress: '',
  consigneeCity: '',
}

export const PermitApplicationForm = ( ) => {
  const [permitData, setPermitData] = useState(initialPermitState);
  const exporter = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  const history = useHistory();
  
  const handleFormSubmit = (e) => {
    e.preventDefault();
    dispatch(createPermit( {...permitData, exportDate: new Date(permitData.exportDate)}, history ));
    console.log(permitData);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setPermitData({ ...permitData, [e.target.name] : e.target.value });
  };


    return (
        <form 
          name="permitApplicationForm" 
          id="permitApplicationForm"   
          onSubmit={handleFormSubmit} 
          noValidate
        >
            <p>CRUDE OIL, GAS AND OTHER PETROLEUM PRODUCTS EXPORT PERMIT</p>
            <div className="company-data">
              <div className="house">
                  <div className="company-details">
                      <div>
                          <span className="title">Company Name: </span>
                          <span className="apply">{exporter?.companyName}</span>
                      </div>
                      <div>
                          <span className="title">Email: </span>
                          <span className="apply">{exporter?.email}</span>
                      </div>
                      <div>
                          <span className="title">RC No: </span>
                          <span className="apply">{exporter?.rcNumber}</span>
                      </div>
                      <div>
                          <span className="title">Phone Number: </span>
                          <span className="apply">{exporter?.phoneNumber}</span>
                      </div>
                      <div>
                          <span className="title">postal Code: </span>
                          <span className="apply">{exporter?.postalCode}</span>
                      </div>
                  </div>
              </div>
            </div>
                  <div className="row">
                    <p>DESCRIBE BELOW ITEMS TO BE EXPORTED WITH THIS PERMIT</p>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          id="productDescription"
                          name="productDescription"
                          className="form-control"
                          placeholder="Product Full Description and Grade (where applicable)"
                          value={permitData.productDescription} 
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          id="quantity"
                          name="quantity"
                          className="form-control"
                          placeholder="Quantity"
                          value={permitData.quantity} 
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          id="fobValue"
                          name="fobValue"
                          className="form-control"
                          placeholder="F.O.B. Value (Estimated in U.S. Dollars)"
                          value={permitData.fobValue} 
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          id="pricePerBarrel"
                          name="pricePerBarrel"
                          className="form-control"
                          placeholder="Price Per Barrel"
                          value={permitData.pricePerBarrel} 
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          id="npxFormNo"
                          name="npxFormNo"
                          className="form-control"
                          placeholder="NXP Form NO."
                          value={permitData.npxFormNo} 
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          id="vesselName"
                          name="vesselName"
                          className="form-control"
                          placeholder="Vessel Name and IMO No."
                          value={permitData.vesselName} 
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          id="periodCovered"
                          name="periodCovered"
                          className="form-control"
                          placeholder="Period Covered"
                          value={permitData.periodCovered} 
                          onChange={handleChange}            
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          id="modeOfExport"
                          name="modeOfExport"
                          className="form-control"
                          placeholder="Mode and Port of Export"            
                          value={permitData.modeOfExport} 
                          onChange={handleChange}                 
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          id="exportDate"
                          name="exportDate"
                          className="form-control"
                          placeholder="Proposed Export Date (dd/mm/yy)"                     
                          value={permitData.exportDate} 
                          onChange={handleChange}      
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <select
                          type="select"
                          id="unit"
                          name="unit"
                          className="form-control"
                          placeholder="Unit"
                          value={permitData.unit} 
                          onChange={handleChange}  
                        >
                        <option selected>units</option>
                        <option value="BBLS">BBLS</option>
                        <option value="METRIC TONS">METRIC TONS</option>
                        </select>
                      </div>
                    </div>
                    <p>DECLARE ULTIMATE CONSIGNEE</p>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          id="consigneeName"
                          name="consigneeName"
                          className="form-control"
                          placeholder="Name of Consignee"  
                          value={permitData.consigneeName} 
                          onChange={handleChange}                        
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          id="consigneeEmail"
                          name="consigneeEmail"
                          className="form-control"
                          placeholder="Consignee Email"    
                          value={permitData.consigneeEmail} 
                          onChange={handleChange}                      
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          id="consigneePhoneNumber"
                          name="consigneePhoneNumber"
                          className="form-control"
                          placeholder="Consignee Phone Number"      
                          value={permitData.consigneePhoneNumber} 
                          onChange={handleChange}                    
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          id="consigneeAddress"
                          name="consigneeAddress"
                          className="form-control"
                          placeholder="Address of Consignee"  
                          value={permitData.consigneeAddress} 
                          onChange={handleChange}                        
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          id="consigneeCity"
                          name="consigneeCity"
                          className="form-control"
                          placeholder="Consignee City"     
                          value={permitData.consigneeCity} 
                          onChange={handleChange}                     
                        />
                      </div>
                    </div>
                    <p>
                      <input type="checkbox"/> 
                      <span className="terms">I've read and agree with the terms on the application 
                        <span className="terms-guide">guidelines</span>
                      </span>
                    </p>
                  <div id="success"></div>
                  <button type="submit" className="click" value="submit">
                    Submit Application
                  </button>
                  </div>
                </form>
    )
}
