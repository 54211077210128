import { FETCH_EXPORTER_PERMITS, CREATE_PERMIT } from '../constants/actionTypes';
import * as api from '../api/index.jsx';

export const createPermit = (permitData, history) => async (dispatch, res) => {
  try {
    const { data } = await api.createPermit(permitData);
    const token = res?.tokenId;
    dispatch({ type: CREATE_PERMIT, payload: {data, token} });
    console.log('permit created successfully');
    history.push("/exporterdashboard"); //push to exporter's home page
  } catch (error) {
    console.log(error);
  }
};

export const getPermits = (id, permit) => async (dispatch, res) => {
    try {
      const { data } = await api.fetchPermit(id, permit);
      dispatch({ type: FETCH_EXPORTER_PERMITS, payload: data });
      console.log('permit(s) successfully fetched');
    } catch (error) {
      console.log(error);
    }
  };

export const getAllPermits = () => async (dispatch) => {
    try {
      const { data } = await api.fetchAllPermits();
      dispatch({ type: FETCH_EXPORTER_PERMITS, payload: data });
      console.log('permit(s) successfully fetched');
    } catch (error) {
      console.log(error);
    }
  };

// export const getAllAdmins = () => async (dispatch) => {
//     try {
//       const { data } = await api.fetchAllAdmins();
//       dispatch({ type: FETCH_EXPORTER_PERMITS, payload: data });
//       console.log('permit(s) successfully fetched');
//     } catch (error) {
//       console.log(error);
//     }
//   };
  
  